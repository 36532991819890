import React from "react";
import {Dialog, DialogActions, DialogContent, Slide} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function ActionDialog({isOpen, setIsOpen, message, title, mainActionName, mainActionFunction, classes}) {
    return <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
    >

        <div className="d-flex justify-content-center mt-3">
            <Typography
                variant="h2"
                component="h2"

            >
                {title}
            </Typography>
        </div>
        <DialogContent className="d-flex" style={{textAlign: "center"}}>{message}</DialogContent>
        <DialogActions className="d-flex justify-content-between mx-8" style={{gap: "1rem"}} >
            <Button style={{flex:"1"}} onClick={() => setIsOpen(false)} classes={{root: classes.buttonContainedWarning,}}
                    variant="contained">
                Cancel
            </Button>
            <Button style={{flex:"1"}} onClick={mainActionFunction.action} variant="contained">
                {mainActionName}
            </Button>
        </DialogActions>
    </Dialog>;
}