import React from "react";
import {connect} from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import {setSnackbar} from "./redux/actions/snackbarAction";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2)
        }
    }
}));

export function CustomizedSnackbar({snackbar, setSnackbar}) {
    const classes = useStyles();
    const {snackbarOpen, snackbarType, snackbarMessage} = snackbar;
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbar(false, snackbarType, snackbarMessage);
    };

    return (
        <div className={classes.root}>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleClose}
                // anchorOrigin={{vertical: 'top' , horizontal: "right"}}
                style={{maxWidth: "30rem"}}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleClose}
                    color={snackbarType}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        snackbar: state.snackbar
    };
}

const mapDispatchToProps = {
    setSnackbar
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomizedSnackbar);
