import {Grid} from "@material-ui/core";
import React from "react";

export default function UnAuthorized() {
    return (
        <div className="mt-3">
            <Grid container >
                <Grid item sm={12} align={"center"}>
                    <h2 align={"center"}>UnAuthorized</h2>
                </Grid>
            </Grid>
        </div>
    );
}