import React from "react";
// import {Card, CardContent} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import componentStyles from "../../argon/assets/theme/views/admin/buttons";
import componentStylesButtons from "../../argon/assets/theme/components/button";
import {TableCell, Tooltip} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Box from "@material-ui/core/Box";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import {useTheme} from "@emotion/react";
import ActionDialog from "./ActionDialog";
import TableActions from "./TableActions";
import AgreementStatus from "./AgreementStatus";
import IconButton from "@material-ui/core/IconButton";
import Person from "@material-ui/icons/Person";
import ViewDialog from "./ViewDialog";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);


let date = new Date();

const tableHead = ["", "Customer Name", "Agreement Title", "Frequency", "Start Date", "Amount", "Status", ""];


export default function AgreementCard({agreements, routeToViewAgreement}) {

    const classes = {...useStyles(), ...useStylesButtons()};
    const theme = useTheme();
    const [isOpen, setIsOpen] = React.useState(false);
    const [isOpenView, setIsOpenView] = React.useState(false);
    const [viewingAgreement, setViewingAgreement] = React.useState(null);
    const [dialogTitle, setDialogTitle] = React.useState('');
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [dialogActionName, setDialogActionName] = React.useState('');
    const [dialogMainAction, setDialogMainAction] = React.useState({
        action: () => {
        }
    });


    function viewRoute(id) {
        return function () {
            routeToViewAgreement(id);
        }
    }

    function pause(agreementId) {
        return {
            action: () => {
                console.log("pause", agreementId)
            }
        }
    }

    function play(agreementId) {
        return {
            action: () => {
                console.log("play", agreementId)
            }
        }
    }

    function deleteAgreement(agreementId) {
        return {
            action: () => {
                console.log("delete", agreementId)
            }
        }
    }

    function openStatusChangeDialog(agreement) {
        setDialogTitle(`${agreement.status === 'PAUSED' ? 'Re-active' : 'Pause'} Recurring Direct Debit`);
        setDialogMessage(`You are about to ${agreement.status === 'PAUSED' ? 'Re-active' : 'Pause'} a recurring direct debit with the title ${agreement.title}`);
        setDialogActionName(agreement.status === 'PAUSED' ? 'Re-active' : 'Pause');
        setDialogMainAction(agreement.status === 'PAUSED' ? play(agreement.agreement_id) : pause(agreement.agreement_id))
        setIsOpen(true);
    }

    function openDeleteDialog(agreement) {
        setDialogTitle("Delete Recurring Direct Debit");
        setDialogMessage(`You are about to delete a recurring direct debit with the title ${agreement.title}`);
        setDialogActionName('Delete');
        setDialogMainAction(deleteAgreement(agreement.agreement_id))
        setIsOpen(true);
    }

    function openViewDialog(agreement) {
        setIsOpenView(true);
        setViewingAgreement(agreement);
    }

    return (
        <div className="card card ">
            <TableContainer>
                <Box component={Table} alignItems="center" marginBottom="0!important">
                    <TableHead>
                        <TableRow>
                            {tableHead.map((prop, key) => (
                                <TableCell
                                    key={key}
                                    classes={{
                                        root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                                    }}
                                >
                                    {prop}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {agreements.map((agreement, key) => (
                            <TableRow key={key}>

                                <TableCell classes={{root: classes.tableCellRoot}}>
                                    <Tooltip title="View customer">
                                        <IconButton
                                            href={process.env.REACT_APP_CLIENT_FORM_URL + "/" + agreement.customer.id}
                                            target="_blank" aria-label="delete"
                                            className="agreement-table-action">
                                            <Box
                                                component={Person}
                                                width="1.25rem!important"
                                                height="1.25rem!important"
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                                <TableCell classes={{root: classes.tableCellRoot}}>
                                    {agreement.customer.first_name + " " + agreement.customer.last_name}
                                </TableCell>
                                <TableCell classes={{root: classes.tableCellRoot}}>
                                    {agreement.title}
                                </TableCell>

                                <TableCell classes={{root: classes.tableCellRoot}}>
                                    {`${agreement.frequency.no_of_units} ${agreement.frequency.time_unit}`}
                                </TableCell>
                                <TableCell classes={{root: classes.tableCellRoot}}>
                                    {agreement.start_date}
                                </TableCell>
                                <TableCell classes={{root: classes.tableCellRoot}}>
                                    {agreement.amount}
                                </TableCell>
                                <TableCell classes={{root: classes.tableCellRoot}}>
                                    <AgreementStatus status={agreement.status}/>
                                </TableCell>
                                <TableCell classes={{root: classes.tableCellRoot}}>
                                    <TableActions openStatusChangeDialog={() => openStatusChangeDialog(agreement)}
                                                  openDeleteDialog={() => openDeleteDialog(agreement)}
                                                  openViewDialog={() => openViewDialog(agreement)}
                                                  status={agreement.status}/>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <ActionDialog
                        isOpen={isOpen} message={dialogMessage}
                        title={dialogTitle}
                        mainActionName={dialogActionName}
                        classes={classes}
                        setIsOpen={setIsOpen}
                        mainActionFunction={dialogMainAction}
                    />
                    <ViewDialog
                        isOpen={isOpenView}
                        setIsOpen={setIsOpenView}
                        agreement={viewingAgreement}
                    />
                </Box>
            </TableContainer>
        </div>
    );
}

