import React from "react";
import ReactDOM from "react-dom";
// import {BrowserRouter, Route, Switch} from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";

import theme from "argon/assets/theme/theme.js";

// plugins styles from node_modules
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "argon/assets/plugins/nucleo/css/nucleo.css";
// core styles
import "argon/assets/scss/argon-dashboard-pro-material-ui.scss?v1.0.0";
import Home from "./Home";
import store from "./redux/store";
import {Provider} from "react-redux";
import configureRequestInterceptor from "interceptors/configureRequestInterceptor"
import configureResponseInterceptor from "./interceptors/configureResponseInterceptor";

configureRequestInterceptor()
configureResponseInterceptor()

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
            <Home/>
        </Provider>
    </ThemeProvider>,
    document.querySelector("#root")
);
