import {Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import {Pause, PlayArrow, Visibility} from "@material-ui/icons";
import Delete from "@material-ui/icons/Delete";
import React from "react";

export default function TableActions({openDeleteDialog, openStatusChangeDialog, status, openViewDialog}) {
    return (
        <>
            <Tooltip title={status === 'PAUSED' ? "Re-activate agreement" : "Pause agreement"}>
                <IconButton aria-label="edit" className="agreement-table-action">
                    <Box
                        component={status === 'PAUSED' ? PlayArrow : Pause}
                        width="1.25rem!important"
                        height="1.25rem!important"
                        onClick={openStatusChangeDialog}
                    />
                </IconButton>
            </Tooltip>
            <Tooltip title="Delete agreement">
                <IconButton aria-label="delete" className="agreement-table-action">
                    <Box
                        component={Delete}
                        width="1.25rem!important"
                        height="1.25rem!important"
                        onClick={openDeleteDialog}
                    />
                </IconButton>
            </Tooltip>
            <Tooltip title="View agreement">
                <IconButton aria-label="view" className="agreement-table-action">
                    <Box
                        component={Visibility}
                        width="1.25rem!important"
                        height="1.25rem!important"
                        onClick={openViewDialog}
                    />
                </IconButton>
            </Tooltip>
        </>
    );
};