import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {TableCell} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import componentStyles from "../../argon/assets/theme/views/admin/buttons";
import componentStylesButtons from "../../argon/assets/theme/components/button";

import * as moment from 'moment'

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);

const tableHead = ["Date", "Time", "Customer", "Customer Reference", "Amount", "Status"];

export default function TransactionsTable({transactions}) {
    const classes = {...useStyles(), ...useStylesButtons()};


    function getDate(timestamp) {
        return moment.unix(+timestamp / 1000).format("ll");
    }

    function getTime(timestamp) {
        return moment.unix(+timestamp/ 1000).format("LT");
    }

    return (
        <TableContainer style={{textAlign: "center"}}>
            <Box component={Table} alignItems="center" marginBottom="0!important">
                <TableHead>
                    <TableRow>
                        {tableHead.map((prop, key) => (
                            <TableCell
                                key={key}
                                classes={{
                                    root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                                }}
                            >
                                {prop}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactions.map((transaction, key) => (
                        <TableRow key={key}>
                            <TableCell classes={{root: classes.tableCellRoot}}>
                                {getDate(transaction.payment_date)}
                            </TableCell>

                            <TableCell classes={{root: classes.tableCellRoot}}>
                                {getTime(transaction.payment_date)}
                            </TableCell>

                            <TableCell classes={{root: classes.tableCellRoot}}>
                                {transaction.customer ? `${transaction.customer.first_name} ${transaction.customer.last_name}` : ''}
                            </TableCell>

                            <TableCell classes={{root: classes.tableCellRoot}}>
                                {transaction.customer_reference}
                            </TableCell>

                            <TableCell classes={{root: classes.tableCellRoot}}>
                                {`$${transaction.total_amount} AUD`}
                            </TableCell>

                            <TableCell classes={{root: classes.tableCellRoot}}>
                                {transaction.transaction_status}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Box>
        </TableContainer>
    )
}