const config = {
    isEnabled: false
}

export default function logInterceptor(isRequest) {
    return (object) => {
        if (config.isEnabled) {
            console.table(`${isRequest? 'request': 'response'} is` , object);
        }
        return object;
    }
}