import {FormGroup, InputAdornment, OutlinedInput} from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import {DatePicker} from "../common/DatePicker";
import {useEffect, useState} from "react";
import Loader from "../common/Loader";
import {fetchTransactions} from "../../api/transactionsAPI";
import TransactionsTable from "./TransactionsTable";

import * as moment from 'moment'

export default function Transactions() {

    const [fromDate, setFromDate] = useState(moment().startOf('day').add(-14, 'days'));
    const [toDate, setToDate] = useState(moment().startOf('day').add(1, 'days'));
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [timeOutId, setTimeOutId] = useState(null);

    useEffect(() => {
        loadTransactions(0, search, fromDate, toDate)
    }, [])

    function loadTransactions(timeout, queryString, startDate, endDate) {
        if (startDate && endDate) {
            if (timeOutId) {
                clearTimeout(timeOutId);
            }
            const id = setTimeout(async () => {
                setIsLoading(true);
                try {
                    const response = await fetchTransactions(queryString, startDate, endDate);
                    setIsLoading(false);
                    response.results ? setTransactions(response.results) : setTransactions([]);
                } catch (e) {
                    setIsLoading(false);
                    setTransactions([]);
                }
            }, timeout);
            setTimeOutId(id);
        }
    }

    function setDateStatus(who, date) {
        if (fromDate && who === "toDate" && new Date(fromDate._d + "") > new Date(date._d + "")) {
            setFromDate(date);
            setToDate(date);
            loadTransactions(0, search, date, date);
        } else if (toDate && who === "fromDate" && new Date(toDate._d + "") < new Date(date._d + "")) {
            setFromDate(date);
            setToDate(date);
            loadTransactions(0, search, date, date);
        } else {
            if (who === "fromDate") {
                setFromDate(date);
                loadTransactions(0, search, date, toDate);
            } else {
                setToDate(date);
                if (fromDate) {
                    loadTransactions(0, search, fromDate, date);
                }
            }
        }
    }

    function isValidFromDate(current) {
        return !toDate || (current.isBefore(toDate) && current.diff(toDate, 'days') >= -30);
    }

    function isValidToDate(current) {
        return !fromDate || (current.isAfter(fromDate) && current.diff(fromDate, 'days') <= 30);
    }

    function getClassNameReactDatetimeDays(date) {
        if (fromDate && toDate && fromDate._d + "" !== toDate._d + "") {
            if (new Date(toDate._d + "") > new Date(date._d + "") &&
                new Date(fromDate._d + "") < new Date(date._d + "")) {
                return " middle-date";
            }
            if (toDate._d + "" === date._d + "") {
                return " end-date";
            }
            if (fromDate._d + "" === date._d + "") {
                return " start-date";
            }
        }
        return "";
    }

    function renderDay(props, currentDate) {
        let classes = props.className;
        classes += getClassNameReactDatetimeDays(currentDate);
        return (
            <td {...props} className={classes}>
                {currentDate.date()}
            </td>
        );
    }

    function onChangeSearchValue(event) {
        const searchValue = event.target.value;
        setSearch(searchValue);
        loadTransactions(500, searchValue, fromDate, toDate);
    }

    return (
        <>
            <div className="d-flex justify-content-end">
                <p className="mb-1 w-50" style={{color: "#0b456e", fontSize: ".875rem"}}>Date range selection required and is limited to 30 days.</p>
            </div>
            <div className="d-flex justify-content-between">
                <FormGroup className="w-25">
                    <OutlinedInput
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={onChangeSearchValue}
                        startAdornment={
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        }
                    />
                </FormGroup>
                <div className="d-flex w-50" style={{gap: "1rem"}}>
                    <div className="w-50">
                        <DatePicker placeholder="From Date" setControlState={setDateStatus}
                                    value={fromDate} name="fromDate" renderDay={renderDay} isValidDate={isValidFromDate}/>
                    </div>
                    <div className="w-50">
                        <DatePicker placeholder="To Date" setControlState={setDateStatus}
                                    value={toDate} name="toDate" renderDay={renderDay} isValidDate={isValidToDate}/>
                    </div>
                </div>
            </div>

            {
                isLoading ? (
                    <div className="mt-5">
                        <Loader />
                    </div>
                ) : transactions.length > 0 ? (
                    <div className="card">
                        <TransactionsTable transactions={transactions} />
                    </div>
                ) : <div className="mt-5 d-flex justify-content-center" />
            }
        </>
    );
}