import store from "redux/store";

const config = {
    excludePath: "authenticate"
}

export default function authInterceptor(request) {
    if (!request.url.includes(config.excludePath)) {
        const token = store.getState().auth.accessToken;
        if (token) {
            request.headers.Authorization = 'Bearer ' + token;
        }
    }
    return request;
}