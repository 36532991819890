import axios from "axios";
import authInterceptor from "./request/authInterceptor";
import logInterceptor from "./common/logInterceptor";

const interceptors = [logInterceptor(true), authInterceptor]

export default function configureRequestInterceptor() {
    axios.interceptors.request.use(request => {
        return interceptors.reduceRight((previousResult, nextInterceptor) => {
            return nextInterceptor(previousResult)
        }, request);
    });
}