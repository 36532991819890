import React from "react";
import {Dialog, DialogActions, DialogContent, Slide} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function ViewDialog({isOpen, setIsOpen, agreement}) {

    function getUnitType() {
        let type = ''
        switch (agreement.frequency.time_unit) {
            case 'MONTH':
                type = 'Month'
                break;
            case 'DAY':
                type = 'Day'
                break;
            case 'WEEK':
                type = 'Week'
                break;
            case 'YEAR':
                type = 'Year'
                break;
            default:
                break;
        }
        if (agreement.frequency.no_of_units > 1 && type) {
            type += 's'
        }
        return type;
    }

    if (!agreement) {
        return null;
    }
    return <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
    >

        <div className="d-flex justify-content-between mx-3 mt-2">
            <Typography
                variant="h2"
                component="h2"
                style={{color: "#0b456e"}}
            >
                View Agreement
            </Typography>
            <div onClick={() => setIsOpen(false)}>
                <Close />
            </div>
        </div>
        <DialogContent className="d-flex flex-column">
            <div className="d-flex">
                <Typography
                    variant="h3"
                    component="h3"
                    style={{color: "#0b456e"}}
                >
                    Customer
                </Typography>

            </div>
            <div className="d-flex justify-content-between">
                <div style={{color: "#0b456e"}}>
                    {`${agreement.customer.first_name} ${agreement.customer.last_name}`}
                </div>
                <div style={{color: "#0b456e"}}>
                    {`${agreement.customer.email}`}
                </div>
            </div>
            <div className="d-flex mt-2" style={{color: "#0b456e"}}>
                {`${agreement.customer.address}, ${agreement.customer.city}, ${agreement.customer.state}, ${agreement.customer.post_code}`}
            </div>
            <div className="d-flex mt-3">
                <Typography
                    variant="h3"
                    component="h3"
                    style={{color: "#0b456e"}}
                >
                    Bank Account
                </Typography>
            </div>
            <div className="d-flex" style={{color: "#0b456e"}}>
                Account Name : {agreement.bank_account.name}
            </div>
            <div className="d-flex mt-2" style={{color: "#0b456e"}}>
                BSB : {agreement.bank_account.bsb}
            </div>
            <div className="d-flex mt-2" style={{color: "#0b456e"}}>
                Account No : {agreement.bank_account.no}
            </div>
            <div className="d-flex mt-3">
                <Typography
                    variant="32"
                    component="h3"
                    style={{color: "#0b456e"}}
                    className="mr-3"
                >
                    Agreement Details
                </Typography>
                <div className={agreement.status === 'ACCEPTED' ? "agreement-status" : "agreement-status agreement-status--disabled"}
                     style={{width: "8rem"}}
                >
                    {agreement.status}
                </div>
            </div>
            <div className="d-flex mt-3" style={{color: "#0b456e"}}>
                Title : {agreement.title}
            </div>
            <div className="d-flex mt-2" style={{color: "#0b456e"}}>
                Amount : ${agreement.amount}
            </div>
            <div className="d-flex mt-2" style={{color: "#0b456e"}}>
                Frequency : {`Every ${agreement.frequency.no_of_units} ${getUnitType()}`}
            </div>
            <div className="d-flex mt-2" style={{color: "#0b456e"}}>
                Time Period : {`${agreement.start_date} - ${agreement.end_date}`}
            </div>
            <div className="d-flex mt-2" style={{color: "#0b456e"}}>
                Already have a signed agreement with the customer : {agreement.skip_consent ? 'Yes' : 'No'}
            </div>
            <div className="d-flex mt-2" style={{color: "#0b456e"}}>
                Disable scheduled payments : {agreement.disable_schedule_payments ? 'Yes' : 'No'}
            </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center" >
            <Button style={{width:"6rem"}} onClick={() => setIsOpen(false)}
                    variant="contained">
                Close
            </Button>
        </DialogActions>
    </Dialog>;
}