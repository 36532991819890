import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import {Dialog, FormGroup, OutlinedInput, Slide} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import Loader from "../common/Loader";
import {tokenCall} from "../../api/tokenAPI";
import TokenCard from "./TokenCard";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


export function Tokens({merchantId, isOpen, setIsOpen}) {
    const [isLoadingTokens, setIsLoadingTokens] = useState(true);
    const [tokens, setTokens] = useState([]);
    const [tokenName, setTokenName] = useState("");
    const [isGenerateToken, setIsGenerateToken] = useState(false);
    const [tokenCountValidation, setTokenCountValidation] = useState(false);

    useEffect(() => {
        if(isOpen) {
            loadTokens();
        }
    }, [isOpen]);

    function tokenGenerate(tokenName) {
        setIsLoadingTokens(true);
        tokenBody.name = tokenName;
        tokenBody.command = "add";
        tokenBody.claimsAsMap.merchant_id = merchantId;
        tokenBody.customClaims.push({key:"merchant_id", value: merchantId});
        tokenBody.scopes = ["direct-debit"];

        tokenCall(tokenBody).then((response) => {
            setTokenName("");
            loadTokens();
            setIsLoadingTokens(false);
            setIsGenerateToken(false);
            setTokenCountValidation(false);
        }).catch(err => {
            setIsLoadingTokens(false);
            setIsGenerateToken(false);
            setTokens([]);
        });
    }

    function onChangeTokenName(event) {
        let value = event.target.value;
        setTokenName(value);
    }

    function loadTokens() {
        setIsLoadingTokens(true);
        tokenBody.command = "query";
        tokenBody.claimsAsMap.merchant_id = merchantId;
        tokenBody.customClaims.push({key:"merchant_id", value: merchantId});
        tokenBody.scopes = ["direct-debit"];

        tokenCall(tokenBody).then((response) => {
            if( response.body ){
                setTokens(response.body)
                if( response.body.length < 2 ){
                    setTokenCountValidation(true);
                }
            }
            setIsLoadingTokens(false);
        }).catch(err => {
            setIsLoadingTokens(false);
            setTokens([]);
        });
    }

    return (
        <Dialog
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setIsOpen(false)}
            maxWidth={"xl"}
        >
            {
                isGenerateToken || !tokenCountValidation ? null : (
                    <div className="d-flex justify-content-between">
                        <Button variant="contained" className="m-4" onClick={ () => setIsGenerateToken(true)}>
                            + Add Token
                        </Button>
                    </div>
                )
            }
            {
                isGenerateToken ? (
                    <div className="row mt-2 m-4">
                        <div className="col-9">
                            <FormGroup>
                                <OutlinedInput
                                    type="text"
                                    placeholder="Token Name"
                                    value={tokenName}
                                    onChange={onChangeTokenName}
                                />
                            </FormGroup>
                        </div>
                        <Button variant="contained" className="col-12 col-sm-4 col-lg-2" style={{height: "max-content"}}
                                onClick={e => tokenGenerate(tokenName)}>
                            Generate
                        </Button>
                    </div>
                ) : null
            }


            {
                viewTokens(isLoadingTokens, tokens, loadTokens)
            }
            <Button variant="contained" className="col-12 col-sm-4 col-lg-2 m-4" style={{height: "max-content"}}
                    onClick={() => {setIsOpen(false); setIsGenerateToken(false);}}>
                Back
            </Button>
        </Dialog>
    );
}

function viewTokens(isLoading, tokens, loadTokens) {
    if (isLoading) {
        return (
            <div className="mt-5">
                <Loader></Loader>
            </div>
        );
    } else {
        return (
            <div className="mt-4 m-3">
                <TokenCard loadTokens={loadTokens} tokens={tokens}></TokenCard>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {merchantId:state.auth.merchantId};
}

const mapDispatchToProps = {
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Tokens);

export const tokenBody =
    {
        "passPhraseSecretManagerKey": "auth-token-passphrase",
        "passPhraseSecretManagerRegion": "AP_SOUTHEAST_2",
        "tokenDuration": 6*30*24*60*60,
        "tokenIssueTimeStamp": Date.now(),
        "issuer": "novattipayments.com",
        "id": "8e23d652-6bb8-413e-831f-6ccff402fddf",
        "subject": "code",
        "customClaims": [],
        "claimsAsMap": {
            "merchant_id": null
        }
    };
