export const customerInitModel = {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    address:'',
    city: '',
    state: '',
    post_code: '',
    country: ''
};