import {useState} from "react";

export default function useAgreementForm(agreementId) {

    const [title, setTitle] = useState("");
    const [amount, setAmount] = useState("");
    const [frequency, setFrequency] = useState("");
    const [frequencyType, setFrequencyType] = useState("DAY");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState(null);
    const [skipConsent, setSkipConsent] = useState(false);
    const [disableSchedulePayments, setDisableSchedulePayments] = useState(false);

    function setControlState(name, event) {
        switch (name) {
            case "title":
                setTitle(event.target.value);
                break;
            case "amount":
                if (event.target.value && +event.target.value > 0) {
                    setAmount(+event.target.value);
                } else {
                    setAmount("");
                }
                break;
            case "frequency":
                if (event.target.value && +event.target.value > 0) {
                    setFrequency(+event.target.value);
                } else {
                    setFrequency("");
                }
                break;
            case "frequencyType":
                setFrequencyType(event.target.value);
                break;
            case "startDate":
                setStartDate(event);
                break;
            case "endDate":
                setEndDate(event);
                break;
            case "skipConsent":
                setSkipConsent(event.target.checked);
                break;
            case "disableScheduledPayments":
                setDisableSchedulePayments(event.target.checked);
                break;
            default:
                break;
        }
    }

    function formValidityState() {
        const requiredFields = [];
        let isStartDateAfterEndDate = false;

        if (amount === '') {
            requiredFields.push("Amount");
        }
        if (!title) {
            requiredFields.push("Title");
        }
        if (!startDate) {
            requiredFields.push("Start Date");
        }
        if (!endDate) {
            requiredFields.push("End Date");
        }
        if (frequency === '') {
            requiredFields.push("Frequency");
        }

        if (startDate && endDate && endDate.isBefore(startDate)) {
            isStartDateAfterEndDate = true
        }

        return {
            requiredFields,
            isStartDateAfterEndDate
        }
    }

    return {
        formValidityState,
        setControlState,
        agreement: {
            amount,
            title,
            start_date: startDate,
            end_date: endDate,
            frequency: {
                time_unit: frequencyType,
                no_of_units: frequency
            },
            skip_consent: skipConsent,
            disable_schedule_payments: disableSchedulePayments
        }
    }
}