import {fetchCustomerDetails} from "../../api/customerAPI";
import {customerInitModel} from "../../model/customerInitModel";
import {bankAccountInitModel} from "../../model/bankAccountInitModel";
import AsyncSelect from "react-select/async";
import {useState} from "react";

export default function CustomerSearch({setCustomer, setBankAccount}) {

    const [isSearchable, setIsSearchable] = useState(true);

    async function searchCustomer(searchValue) {
        const res = await fetchCustomerDetails(searchValue);
        return res.data;
    }

    function onChangeSelectedOption(customer) {
        if (customer) {
            setIsSearchable(false);
            const customerModel = {
                id: customer.id,
                first_name: customer.first_name,
                last_name: customer.last_name,
                email: customer.email,
                address:customer.address,
                city: customer.city,
                state: customer.state,
                post_code: customer.zip_code,
                country: 'AU'
            }
            const bankAccountModel =  {
                bsb: customer.bank_code,
                no: customer.bank_account,
                name: `${customer.first_name} ${customer.last_name}`.trim()
            };
            setCustomer(customerModel);
            setBankAccount(bankAccountModel);
        } else {
            setIsSearchable(true);
            setCustomer(customerInitModel);
            setBankAccount(bankAccountInitModel);
        }

    }

    const customStyles = {
        control: (base, state) => ({
            ...base,
            height: "100%",
            minHeight: "100%",
            color: "#8898aa",
            border: state.isFocused ? '2px solid #5e72e4' : '1px solid #ced4da',
            boxShadow: 'none',
            '&:hover': {
                border: state.isFocused ? '2px solid #5e72e4' : '1px solid #ced4da',
            }
        }),
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                color: '#ced4da',
                fontSize: '.875rem',
                fontWeight: '400',
                fontFamily: 'inherit'
            }
        },
        singleValue: (provided) => {
            return {
                ...provided,
                color: '#8898aa'
            }
        },
        option: provided => {
            return {
                ...provided,
                color: '#2d353b'
            }
        },
        input: provided => {
            return {
                ...provided,
                color: '#8898aa'
            }
        }
    };

    return (
        <AsyncSelect
            className="col-12 col-sm-8 col-lg-10"
            styles={customStyles}
            isClearable={true}
            isSearchable={isSearchable}
            loadOptions={searchCustomer}
            onChange={onChangeSelectedOption}
            placeholder = "Search Customers"
            noOptionsMessage = {e => "No Customers Found"}
            getOptionLabel={(customer) => `${customer.first_name} ${customer.last_name} | ${customer.email} | ${customer.phone}`}
        />
    );
}