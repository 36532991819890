import axios from "axios";
const CancelToken = axios.CancelToken;
export const agreementApiCancelSource = CancelToken.source();

export function loadAgreements(queryString) {
    return axios.get(`${process.env.REACT_APP_AGREEMENT_HOST_URL}/v1/agreement${queryString? '?query_string='+ queryString : '' }`, {
        cancelToken: agreementApiCancelSource.token
    });
}

export function saveAgreement(agreementRequest) {
    return axios.post(`${process.env.REACT_APP_AGREEMENT_HOST_URL}/v1/agreement`, agreementRequest);
}