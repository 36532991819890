import store from "redux/store";
import axios from "axios";
import logInterceptor from "./common/logInterceptor";
import {mapResponseInterceptor} from "./response/mapResponseInterceptor";
import {setSnackbar} from "../redux/actions/snackbarAction";

const interceptors = [logInterceptor(false), mapResponseInterceptor]

export default function configureResponseInterceptor() {
    axios.interceptors.response.use(response => {
        return interceptors.reduceRight((previousResult, nextInterceptor) => {
            return nextInterceptor(previousResult)
        }, response);

    }, error => {
        if (!error || !error.response) {
            store.dispatch(setSnackbar(true, "error", "Error occurred!"));
        } else if (error.response.status === 403 || error.response.status === 401) {
            store.dispatch(setSnackbar(true, "error", "UnAuthorized Action"));
        } else if (error.response.data && error.response.data.details && error.response.data.details.message) {
            store.dispatch(setSnackbar(true, "error", error.response.data.details.message));
        } else {
            store.dispatch(setSnackbar(true, "error", "Server error. Please try again"));
        }
        return Promise.reject(error);
    });
}