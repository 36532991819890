import React, {useEffect} from "react";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import AttachFile from "@material-ui/icons/Description";
import VpnKey from "@material-ui/icons/VpnKey";
import {Tooltip} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import {Tokens} from "./tokens/Tokens";

const classes = {
    headerIcon: {
        width: "2rem",
        height: "100%",
        '&:hover': {
            cursor: 'grab'
        }
    }
}

export default function Header({merchantId}) {
    const [activeButton, setActiveButton] = React.useState('transactions');

    const [isOpen, setIsOpen] = React.useState(false);

    const history = useHistory();
    useEffect(() => {
        if (history.location.pathname.includes("transactions") || history.location.pathname === "/") {
            setActiveButton("transactions");
        } else {
            setActiveButton("agreements");
        }
    }, [])

    function routeTo(route) {
        return function (e) {
            e.preventDefault();
            history.push("/"+route);
            setActiveButton(route);
        }
    }

    function downloadManual() {
        axios({
            url: process.env.REACT_APP_DD_MANUAL_URL,
            method: 'GET',
            responseType: 'application/pdf',
        }).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'file.pdf');
            document.body.appendChild(fileLink);

            fileLink.click();
        });
    }

    return (
        <>
            <div className="d-flex justify-content-end">
                <Tooltip title="View Tokens">
                    <a onClick={() => setIsOpen(true)} target="_blank" rel="noopener noreferrer">
                        <IconButton aria-label="view">
                            <Box
                                component={VpnKey}
                                width="2rem!important"
                                height="2rem!important"
                                color="#0b456e"
                            />
                        </IconButton>
                    </a>
                </Tooltip>
                <Tooltip title="Direct debit portal manual">
                    <a href={process.env.REACT_APP_DD_MANUAL_URL} download={"direct-debit.pdf"} target="_blank" rel="noopener noreferrer">
                        <IconButton aria-label="view">
                            <Box
                                component={AttachFile}
                                width="2rem!important"
                                height="2rem!important"
                                color="#0b456e"
                                // onClick={downloadManual}
                            />
                        </IconButton>
                    </a>
                </Tooltip>
            </div>
            <div className="d-flex justify-content-between">
                <Button onClick={routeTo("transactions")} color={activeButton === "transactions" ? 'default':'secondary'}
                        variant="contained" className="mx-2 mb-2 mt-0" style={{flex:"1"}}>
                    Transactions
                </Button>
                <Button onClick={routeTo("agreements")}  color={activeButton === "agreements" ? 'default':'secondary'}
                        variant="contained" className="mx-2 mb-2 mt-0" style={{flex:"1"}}>
                    Agreements
                </Button>
                {/*<NavLink to="/transactions" activeStyle={activeStyle}>*/}
                {/*    Transactions*/}
                {/*</NavLink>*/}
                {/*<NavLink to="/agreements" activeStyle={activeStyle}>*/}
                {/*    Agreements*/}
                {/*</NavLink>*/}
                <Tokens
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    merchantId={merchantId}
                />
            </div>
        </>
    );
}