import axios from "axios";

export function fetchTransactions(queryString, fromDate, toDate) {
    if (fromDate) {
        fromDate = fromDate.format("YYYY-MM-DD")
    }
    if (toDate) {
        toDate = toDate.format("YYYY-MM-DD")
    }
    const queryParams = `?payment_method=directdebit${fromDate ? '&from_date=' + fromDate : ''}${toDate ? '&end_date=' + toDate : ''}${queryString ? '&query_string=' + queryString : ''}`
    return axios.get(`${process.env.REACT_APP_DD_HOST_URL}/v1/transactions${queryParams}`);
}