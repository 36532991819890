import ReactDatetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import DateRange from "@material-ui/icons/DateRange";
import FormGroup from "@material-ui/core/FormGroup";

export function DatePicker({placeholder, value, setControlState, name, isValidDate, renderDay}) {

    return (
        <FormGroup>
            <ReactDatetime
                inputProps={{
                    placeholder,
                    className: ""
                }}
                closeOnSelect={true}
                value={value}
                timeFormat={false}
                onChange={e => setControlState(name, e)}
                isValidDate={isValidDate ? isValidDate : () => true}
                renderDay={renderDay ? renderDay : null}
                renderInput={(dateInputProps) => (
                    <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                    >
                        <OutlinedInput
                            {...dateInputProps}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Box
                                        component={DateRange}
                                        marginRight=".5rem"
                                    />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                )}
            />
        </FormGroup>
    );
}