import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import componentStyles from "../../argon/assets/theme/views/admin/buttons";
import componentStylesButtons from "../../argon/assets/theme/components/button";
import {TableCell} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Box from "@material-ui/core/Box";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import ActionDialog from "../agreements/ActionDialog";
import TableActions from "../tokens/TableActions";
import ViewDialog from "../agreements/ViewDialog";
import {tokenBody} from "./Tokens";
import {tokenCall} from "../../api/tokenAPI";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);
const tableHead = ["Token Name", "Created", "Expiry", "Last Used", ""];

export default function TokenCard({tokens, loadTokens}) {

    const classes = {...useStyles(), ...useStylesButtons()};
    const [isOpen, setIsOpen] = React.useState(false);
    const [isOpenView, setIsOpenView] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState('');
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [dialogActionName, setDialogActionName] = React.useState('');
    const [dialogMainAction, setDialogMainAction] = React.useState({
        action: () => {
        }
    });
    const [isLoadingTokens, setIsLoadingTokens] = useState(true);
    const [visibleToken, setVisibleToken] = React.useState("");
    const [selectedToken, setSelectedToken] = React.useState("");

    function deleteToken(token) {
        return {
            action: () => {
                tokenBody.command = "delete";
                tokenBody.authorizationToken = "Bearer " + token.token;
                tokenBody.customClaims = []
                tokenBody.customClaims.push({key:"merchant_id", value: token.merchantId});
                tokenBody.scopes = null;

                tokenCall(tokenBody).then((response) => {
                    setIsLoadingTokens(false);
                    loadTokens();

                }).catch(err => {
                    setIsLoadingTokens(false);
                });
                setIsOpen(false);
            }
        }
    }

    function reGenerateToken(token) {
        return {
            action: () => {
                tokenBody.command = "regenerate";
                tokenBody.name = token.name;
                tokenBody.authorizationToken = "Bearer " + token.token;
                tokenBody.customClaims = []
                tokenBody.customClaims.push({key:"merchant_id", value: token.merchantId});
                tokenBody.scopes = ["direct-debit"];

                tokenCall(tokenBody).then((response) => {
                    loadTokens();
                    setIsLoadingTokens(false);
                }).catch(err => {
                    setIsLoadingTokens(false);
                });
                setIsOpen(false);
            }
        }
    }

    function openReGenerateDialog(token) {
        setDialogTitle(`Re-generate Token`);
        setDialogMessage(`You are about to generate the token. Once you proceed the old token can no longer be used`);
        setDialogActionName('Proceed');
        setDialogMainAction(reGenerateToken(token))
        setIsOpen(true);
    }

    function openDeleteDialog(token) {
        setDialogTitle("Delete Token");
        setDialogMessage(`You are about to delete the token!`);
        setDialogActionName('Delete');
        setDialogMainAction(deleteToken(token))
        setIsOpen(true);
    }

    function changeVisibleToken(token) {
        if(token.token === visibleToken){
            setVisibleToken("");
            setSelectedToken("");
        }
        else {
            setVisibleToken(token.token)
            setSelectedToken(token);
        }
    }

    function getConvertedDateTime(time, addition) {
        const date = new Date(time);
        if(addition) {
            date.setSeconds(date.getSeconds() + addition);
        }
        const ampm = date.getSeconds() >= 12 ? 'PM' : 'AM';
        var hours = date.getHours();
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        return date.getUTCDate() + "." + (date.getMonth()+1) + "." + date.getFullYear() + " " + hours + ":" + date.getMinutes() + ":" + date.getSeconds() + ampm;
    }

    return (
        <div>
        <div className="card card">
            <TableContainer>
                <Box component={Table} alignItems="center" marginBottom="0!important">
                    <TableHead>
                        <TableRow>
                            {tableHead.map((prop, key) => (
                                <TableCell
                                    key={key}
                                    classes={{
                                        root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                                    }}
                                >
                                    {prop}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tokens.map((token, key) => (
                            <TableRow key={key}>
                                <TableCell classes={{root: classes.tableCellRoot}}>
                                    {token.name}
                                </TableCell>
                                <TableCell classes={{root: classes.tableCellRoot}}>
                                    {getConvertedDateTime(token.created, false)}
                                </TableCell>
                                <TableCell classes={{root: classes.tableCellRoot}}>
                                    {
                                        getConvertedDateTime(token.created, token.expiry)
                                    }
                                </TableCell>
                                <TableCell classes={{root: classes.tableCellRoot}}>
                                    {getConvertedDateTime(token.lastUsed, false)}
                                </TableCell>
                                <TableCell classes={{root: classes.tableCellRoot}}>
                                    <TableActions openStatusChangeDialog={() => openReGenerateDialog(token)}
                                                  openDeleteDialog={() => openDeleteDialog(token)}
                                                  openViewDialog={() => changeVisibleToken(token)}
                                                  status={token.status}
                                                  isSelected={token===selectedToken}/>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <ActionDialog
                        isOpen={isOpen} message={dialogMessage}
                        title={dialogTitle}
                        mainActionName={dialogActionName}
                        classes={classes}
                        setIsOpen={setIsOpen}
                        mainActionFunction={dialogMainAction}
                    />
                    <ViewDialog
                        isOpen={isOpenView}
                        setIsOpen={setIsOpenView}
                    />
                </Box>
            </TableContainer>
            <div className={"m-4"}>
                {
                    visibleToken ? visibleToken : null
                }
            </div>
        </div>

    </div>
    );

}


